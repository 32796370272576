import {
  BlockList,
  Button,
  Checkbox,
  Grid,
  GridCell,
  Input,
  PageSection,
  Spacer,
  Stack,
  TabPanel,
  TabPanelProps,
  TextBody,
  Text,
  TextSubtitle,
  TextTitle,
  Tooltip,
  Select,
  SelectOption,
  Divider,
  EntityTypes,
  Filepond,
} from '@pypestream/design-system';
import { transformProfilePicture } from '@pypestream/utils';
import { ConsentStatusEnum } from '@pypestream/api-services';
import { TranslationComponent } from '@pypestream/translations';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader } from '../../../components';
import {
  sendManagerEvent,
  sendUserEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useUserCtxSelector,
} from '../../../xstate/app.xstate';
import { MyAccountFormValues, useMyAccountForm } from '../../../hooks';
import { kratosApi } from '../../../kratos.api';
import {
  Countries,
  Languages,
  Timezones,
} from '../../../xstate/manager.xstate';

export const General: FC<TabPanelProps> = ({ active, name }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserCtxSelector((ctx) => ({
    user: ctx.user,
  }));
  const { userInfo, routes, countries, timeZones, languages } =
    useManagerCtxSelector((ctx) => ({
      userInfo: ctx.userInfo,
      routes: ctx.routes,
      countries: ctx.countries,
      timeZones: ctx.timezones,
      languages: ctx.languages
        .filter(({ languageCode }) => {
          return (
            !languageCode ||
            // ctx.localizationSettingsConfig?.user.supportedLanguageCodes || []
            // @TODO: Need this for November's 2024 demo (Arabic and English US only)
            ['en', 'ar'].includes(languageCode)
          );
        })
        // @TODO: Need this for November's 2024 demo (Arabic and English US only)
        .filter(
          ({ locale }) =>
            locale === 'ar-AE' || locale === 'en-US' || locale === ''
        ),
    }));

  const userPreferencesUpdated = useManagerStateMatches(
    'orgRelated.ready.userInfo.updated'
  );

  const goToPreviousPage = useCallback(() => {
    // Redirect to the last page where you were on before you navigated to My Account page.
    const searchParams = new URLSearchParams(location.search);
    const returnUrl = searchParams.get('original_app_url');
    if (returnUrl) {
      window.location.href = returnUrl;
    } else {
      navigate(-1);
    }
  }, [navigate, location.search]);

  useEffect(() => {
    if (!userPreferencesUpdated) return;
    goToPreviousPage();
  }, [goToPreviousPage, userPreferencesUpdated, routes.home]);

  const authMethod = userInfo?.recommendedAuthMethod;
  const isGoogleProvider = authMethod === 'google-sso' || authMethod === 'oidc';
  const loading = !user?.email || !authMethod;

  const formValues = useMemo<MyAccountFormValues>(
    () => ({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      jobTitle: user?.settings?.jobTitle,
      profilePhoto: transformProfilePicture(user?.profilePhoto),
      email: user?.email,
      requiredConsentStatus: user?.requiredConsentStatus,
      optionalConsentStatus: user?.optionalConsentStatus,
      defaultLanguage: user?.settings?.defaultLanguage,
      defaultTimezone: user?.settings?.defaultTimezone,
      country: user?.settings?.country,
    }),
    [user]
  );

  const translatedLanguages = useMemo<Languages>(() => {
    if (languages.length > 0) {
      return [
        {
          name: 'Select a language',
          id: '',
          locale: '',
          __typename: 'Locale',
          languageCode: '',
        },
        ...languages.slice(1),
      ];
    }

    return [];
  }, [languages]);

  const translatedTimeZones = useMemo<Timezones>(() => {
    if (timeZones.length > 0) {
      return [
        {
          label: 'Select a time zone',
          identifier: '',
          id: '',
          __typename: 'TimeZone',
        },
        ...timeZones.slice(1),
      ];
    }

    return [];
  }, [timeZones]);

  const translatedCountries = useMemo<Countries>(() => {
    if (countries.length > 0) {
      return [
        {
          name: 'Select a country',
          id: '',
          code: '',
          __typename: 'Country2',
        },
        ...countries.slice(1),
      ];
    }

    return [];
  }, [countries]);

  const { form, watchedValues, onSubmit, setValue } = useMyAccountForm({
    formValues,
    onSubmit: (values: MyAccountFormValues) => {
      sendManagerEvent({
        type: 'manager.updateUserPreferences',
        values: {
          ...values,
        },
      });
    },
  });

  useEffect(() => {
    if (!user) return;

    form.reset({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      jobTitle: user?.settings?.jobTitle,
      profilePhoto: transformProfilePicture(user?.profilePhoto),
      email: user?.email,
      requiredConsentStatus: user?.requiredConsentStatus,
      optionalConsentStatus: user?.optionalConsentStatus,
      defaultLanguage: user?.settings?.defaultLanguage,
      defaultTimezone: user?.settings?.defaultTimezone,
      country: user?.settings?.country,
    });
  }, [form, user]);

  const redirectToResetPasswordPage = async () => {
    if (!watchedValues.email) return;

    sendUserEvent({
      type: 'user.signOut',
      redirectURL: `${import.meta.env.FE_AUTH_URL}/recovery?emailId=${
        watchedValues.email
      }`,
    });
  };

  const formElements = {
    get firstName() {
      return (
        <Input
          {...form.register('firstName')}
          placeholder={`${t('manager/preferences:general.add_name')}`}
          disabled={isGoogleProvider}
          variant="outlined"
          required
          test-id="my-account-first-name"
        />
      );
    },
    get lastName() {
      return (
        <Input
          {...form.register('lastName')}
          placeholder={`${t('manager/preferences:general.add_surname')}`}
          disabled={isGoogleProvider}
          variant="outlined"
          required
          test-id="my-account-last-name"
        />
      );
    },
    get email() {
      return (
        <Input
          {...form.register('email')}
          placeholder={`${t('manager/preferences:general.add_email_address')}`}
          type="email"
          disabled
          variant="outlined"
          test-id="my-account-email"
        />
      );
    },
    get jobTitle() {
      return (
        <Input
          {...form.register('jobTitle')}
          variant="outlined"
          placeholder={`${t('manager/preferences:general.add_job_title')}`}
          test-id="my-account-job-title"
        />
      );
    },
    get cookiePolicy() {
      return (
        <Stack gutter="xsmall" alignItems="center">
          <Checkbox
            size="small"
            {...form.register('optionalConsentStatus')}
            defaultChecked={
              formValues.optionalConsentStatus === ConsentStatusEnum.Accepted
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked) {
                setValue('optionalConsentStatus', ConsentStatusEnum.Accepted, {
                  shouldDirty: true,
                });
              } else {
                setValue('optionalConsentStatus', ConsentStatusEnum.Declined, {
                  shouldDirty: true,
                });
              }
            }}
          >
            <TextBody size="small">
              {t('manager/preferences:general.cookie_policy_label_prefix')}
              &nbsp;
              <a
                href="https://app.termly.io/document/cookie-policy/84d2833f-fd93-4e1e-bcf7-4b18f161aa77"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.cookie_policy')}
              </a>
              &nbsp;
              {t('manager/preferences:general.cookie_policy_label_suffix')}
            </TextBody>
          </Checkbox>
        </Stack>
      );
    },
    get privacyPolicy() {
      return (
        <>
          <Stack
            gutter="xsmall"
            alignItems="center"
            style={{ display: 'none' }}
          >
            <Select
              {...form.register('requiredConsentStatus')}
              defaultValue={formValues.requiredConsentStatus}
              onChange={(e) => {
                setValue(
                  'requiredConsentStatus',
                  e.target.value as ConsentStatusEnum,
                  {
                    shouldDirty: true,
                  }
                );
              }}
            >
              {Object.keys(ConsentStatusEnum).map((status) => (
                <SelectOption key={status} value={status.toUpperCase()}>
                  {status}
                </SelectOption>
              ))}
            </Select>
          </Stack>

          <Text size="3xsmall">
            <Stack gutter="xsmall" alignItems="center">
              <a
                href="https://app.termly.io/document/privacy-policy/ac1820c8-6c9c-4ae8-a99f-09c17cec85f1"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.privacy_policy')}
              </a>
              <Divider vertical />
              <a
                href="https://app.termly.io/document/terms-of-service/85363fa8-fff8-4aeb-963e-cf55bbe4ef7f"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.terms_conditions')}
              </a>
              <Divider vertical />
              <a
                href="https://app.termly.io/notify/ac1820c8-6c9c-4ae8-a99f-09c17cec85f1"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.dsar_form')}
              </a>
            </Stack>
          </Text>
        </>
      );
    },
    get profilePhoto() {
      return (
        <>
          <Filepond
            test-id="my-account-profile-picture"
            readonly={isGoogleProvider}
            entityType={EntityTypes.USER}
            entityId={userInfo?.id}
            accountId={userInfo?.defaultOrgId}
            accept={['image/png', 'image/jpeg']}
            previewLabel={`${t(
              `manager/preferences:general.${
                isGoogleProvider
                  ? 'upload_profile_picture_on_google'
                  : 'upload_a_file_here'
              }`
            )}`}
            previewCta={!isGoogleProvider ? 'Replace image' : ''}
            pictureSrc={watchedValues.profilePhoto}
            onChange={({ url }) => {
              setValue('profilePhoto', url, {
                shouldDirty: true,
              });
            }}
          ></Filepond>
        </>
      );
    },
    get defaultLanguage() {
      return (
        <Select
          test-id="my-account-language"
          {...form.register('defaultLanguage')}
          defaultValue={formValues.defaultLanguage}
          variant="outlined"
          placeholder="Select a language"
          onChange={(e) => {
            setValue('defaultLanguage', e.target.value as string, {
              shouldDirty: true,
            });
          }}
          helpText={`${t('manager/preferences:general.applies_only_to_manager')}`}
        >
          {translatedLanguages.map((language) => (
            <SelectOption key={language.id} value={language.locale}>
              {language.name}
            </SelectOption>
          ))}
        </Select>
      );
    },
    get defaultTimezone() {
      return (
        <Select
          test-id="my-account-timezone"
          {...form.register('defaultTimezone')}
          defaultValue={formValues.defaultTimezone}
          variant="outlined"
          searchKeys={['searchKeys', 'identifier', 'label']}
          data={translatedTimeZones}
          placeholder="Select a time zone"
          onChange={(e) => {
            setValue('defaultTimezone', e.target.value as string, {
              shouldDirty: true,
            });
          }}
          helpText={`${t('manager/preferences:general.applies_only_to_manager')}`}
        >
          {translatedTimeZones.map((timeZone) => (
            <SelectOption key={timeZone.id} value={timeZone.id}>
              {timeZone.label}
            </SelectOption>
          ))}
        </Select>
      );
    },
    get country() {
      return (
        <Select
          test-id="my-account-country"
          {...form.register('country')}
          defaultValue={formValues.country}
          variant="outlined"
          placeholder="Select a country"
          onChange={(e) => {
            setValue('country', e.target.value as string, {
              shouldDirty: true,
            });
          }}
          helpText={`${t('manager/preferences:general.the_country_you_are_a_citizen_of')}`}
        >
          {translatedCountries.map((country) => (
            <SelectOption key={country.id} value={country.code}>
              {country.name}
            </SelectOption>
          ))}
        </Select>
      );
    },
  };

  return loading ? (
    <Loader relative />
  ) : (
    <TabPanel name={name} active={active}>
      <Spacer size="xlarge" />
      <form onSubmit={onSubmit}>
        <PageSection>
          <TextTitle test-id="my-account-subtitle-details" size="xsmall">
            <TranslationComponent i18nKey="manager/preferences:general.details">
              Details
            </TranslationComponent>
          </TextTitle>
          <Spacer size="large" />

          <BlockList>
            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-first-name-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.name">
                    Name*
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Grid alignItems="center">
                  <GridCell xsmall="12" medium="6">
                    {formElements.firstName}
                  </GridCell>
                  <GridCell xsmall="12" medium="6">
                    {formElements.lastName}
                  </GridCell>
                </Grid>
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-email-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.email_address">
                    Email address*
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Tooltip content="To edit your email address, contact an Admin">
                  {formElements.email}
                </Tooltip>
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-job-title-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.job_title">
                    Job Title
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.jobTitle}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-profile-picture-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.profile_picture">
                    Profile Picture
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.profilePhoto}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-cookie-policy-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.cookie_policy">
                    Cookie Policy
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.cookiePolicy}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-other-policies-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.other_policies">
                    Other Policies
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.privacyPolicy}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-reset-password-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.reset_password">
                    Reset Password
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {!isGoogleProvider ? (
                  <Button
                    variant="secondary"
                    disabled={!watchedValues.email}
                    onClick={redirectToResetPasswordPage}
                  >
                    <TranslationComponent i18nKey="manager/preferences:general.reset_password">
                      Reset Password
                    </TranslationComponent>
                  </Button>
                ) : (
                  <TextBody>
                    <TranslationComponent i18nKey="manager/preferences:general.reset_password_help">
                      To reset your password, visit your Google account
                    </TranslationComponent>
                  </TextBody>
                )}
              </GridCell>
            </Grid>
          </BlockList>
        </PageSection>

        <PageSection>
          <TextTitle
            test-id="my-account-subtitle-language-timezone"
            size="xsmall"
          >
            <TranslationComponent i18nKey="manager/preferences:general.language_timezone">
              Language & Timezone
            </TranslationComponent>
          </TextTitle>
          <Spacer size="large" />

          <BlockList>
            <Grid>
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-country-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.country">
                    Country
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.country}
              </GridCell>
            </Grid>
            <Grid>
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-timezone-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.default_timezone">
                    Default timezone
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.defaultTimezone}
              </GridCell>
            </Grid>
            <Grid>
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-language-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.default_language">
                    Default language
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.defaultLanguage}
              </GridCell>
            </Grid>
          </BlockList>
        </PageSection>

        <Stack justifyContent="end" gutter="small">
          <Button
            test-id="my-account-cancel"
            variant="ghost"
            size="large"
            onClick={goToPreviousPage}
          >
            <TranslationComponent i18nKey="manager/common:cancel">
              Cancel
            </TranslationComponent>
          </Button>
          <Button
            test-id="my-account-save-changes"
            size="large"
            type="submit"
            disabled={!Object.keys(form.formState.dirtyFields).length}
          >
            <TranslationComponent i18nKey="manager/common:save">
              Save Changes
            </TranslationComponent>
          </Button>
        </Stack>
      </form>
    </TabPanel>
  );
};
