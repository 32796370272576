/* eslint-disable react/prop-types */
import {
  AppletInstance,
  ProjectEnvironmentConfig,
} from '@pypestream/api-services';
import { CreateAppletInstanceMutationVariables } from '@pypestream/api-services/urql';
import {
  Input,
  InputProps,
  Textarea,
  TextareaProps,
} from '@pypestream/design-system';
import { useTranslation } from '@pypestream/translations';
import { FC, ForwardedRef, forwardRef } from 'react';
import { UseFormReturn, useController } from 'react-hook-form';

import { Product } from '../../utils';
import { ProductCTA } from '../product-cta';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReturnAppletFormType = UseFormReturn<any, unknown, undefined>;

export type FormAppletInfoType = Pick<
  AppletInstance,
  'name' | 'description' | 'projectEnvironmentConfigId'
>;

export type FormAppletToolingType = Pick<AppletInstance, 'appletVersionId'>;

export type FormAppletEnvironmentsType = {
  projectEnvironmentConfigs: ProjectEnvironmentConfig[];
};

export type FormAppletStateType = FormAppletInfoType & FormAppletToolingType;

export type OutputAppletFormType = CreateAppletInstanceMutationVariables;

export const AppletEnvironments = {
  Production: 'Production',
  Testing: 'Testing',
  Development: 'Development',
};

export class AppletFormFields {
  public AppletNameField = forwardRef(
    (
      props: InputProps & { control: ReturnAppletFormType['control'] },
      ref: ForwardedRef<InputProps>
    ) => {
      const [t] = useTranslation();
      const { field: nameField, fieldState: nameFiledState } = useController({
        name: 'name',
        control: props.control || undefined,
        rules: {
          required:
            t(
              'manager/projects:projectDetails.createAppletModal.step1.required',
              {
                defaultValue: 'This is a required field',
              }
            ) || '',
        },
      });

      return (
        <Input
          ref={ref}
          placeholder={
            t('manager/projects:projectDetails.createAppletModal.step1.name', {
              defaultValue: 'Applet name',
            }) || ''
          }
          type="text"
          autocomplete="off"
          value={nameField.value}
          helpText={nameFiledState.error?.message}
          hasError={nameFiledState.invalid}
          name={nameField.name}
          onChange={nameField.onChange}
          {...props}
        />
      );
    }
  );

  public AppletDescriptionField = forwardRef(
    (props: TextareaProps, ref: ForwardedRef<TextareaProps>) => {
      const [t] = useTranslation();

      return (
        <Textarea
          ref={ref}
          placeholder={
            t(
              'manager/projects:projectDetails.createAppletModal.step1.description',
              {
                defaultValue: 'Description (optional)',
              }
            ) || ''
          }
          autocomplete="off"
          {...props}
        />
      );
    }
  );

  public AppletToolField: FC<{
    logo: Product['logo'];
    label: string;
    productId: string;
    confirmRemove?: boolean;
    form: ReturnAppletFormType;
  }> = ({ form, logo, label, productId }) => {
    const [t] = useTranslation();

    const { field } = useController({
      name: 'appletVersionId',
      control: form.control,
      rules: { validate: (value) => !!value?.length },
    });

    const onInputChange = (checked: boolean) => {
      if (checked) {
        field.onChange(productId);
      } else {
        field.onChange(null);
      }
    };

    const fieldIsChecked = !!field.value?.includes(productId);
    const eventHandler = { onInputChange };

    return (
      <ProductCTA
        logo={logo}
        inputName={field.name}
        inputValue={productId}
        disabled={false}
        name={
          t(
            `manager/projects:projectDetails.createAppletModal.step2.applets.${label.toLowerCase()}`,
            {
              defaultValue: label,
            }
          ) || ''
        }
        checked={fieldIsChecked}
        {...eventHandler}
      />
    );
  };
}
