import { createComponent } from '@lit/react';
import * as React from 'react';
import { TranslateInterface } from '../base-element/mixins/translation-mixin';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';
import { TagWC } from './tag.wc';

export type TagProps = GenerateComponentTypeWithChildren<TagWC> &
  TranslateInterface;

export type TagPropsWithRef = GeneratePropsWithRef<
  TagProps & {
    onRemove: (event: CustomEvent) => void;
  }
>;

export const Tag = createComponent({
  tagName: TagWC.tagname,
  elementClass: TagWC,
  react: React,
  events: {
    onRemove: 'ps-remove',
  },
}) as TagPropsWithRef;
