import {
  AccordionGroup,
  AccordionItem,
  Button,
  Icon,
  Menu,
  MenuItem,
  PageBody,
  PageSidenav,
  Spacer,
  Text,
  TextBody,
  TextSubtitle,
} from '@pypestream/design-system';
import {
  TranslationComponent,
  TranslationKeys,
  useTranslation,
} from '@pypestream/translations';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectEnvironmentsType } from '../../../components';
import { getEnvironmentName } from '../../../utils';
import { useManagerCtxSelector } from '../../../xstate/app.xstate';
import { IPage } from '../../types';
import { RenderProjectDetailsContent } from './tabs';
import { sortEnvironmentsByName } from './tabs/render-page-content';

import './project-details.scss';

export enum ProjectDetailsTabs {
  general = '',
  environments = 'environments',
}

const tabs: {
  label: string;
  value: ProjectDetailsTabs;
  accordion: boolean;
  translationKey: TranslationKeys;
}[] = [
  {
    label: 'General',
    value: ProjectDetailsTabs.general,
    accordion: false,
    translationKey: 'manager/projects:sidebar.general',
  },
  {
    label: 'Environments',
    value: ProjectDetailsTabs.environments,
    accordion: true,
    translationKey: 'manager/projects:sidebar.environments',
  },
];

export const ProjectDetails: FC<IPage> = ({
  title,
  tab,
  hasSidenav,
  hideDeleteButton,
  environmentId,
}) => {
  const navigate = useNavigate();
  const { routes, project } = useManagerCtxSelector((ctx) => {
    return {
      project: ctx.projects?.find(
        ({ projectId }) => projectId === ctx.selectedProject
      ),
      routes: ctx.routes,
    };
  });

  const [t] = useTranslation();

  const projectName =
    project?.name ||
    t('manager/projects:sidebar.projectDetails', {
      defaultValue: title,
    });

  const sortedProjectEnvironments = sortEnvironmentsByName(
    (project?.projectEnvironmentConfig || []).map(
      ({ domains, appletInstances, environment, description, id }) => ({
        domains:
          domains?.map(({ type, url }) => ({
            type,
            url,
          })) || [],
        description,
        name: getEnvironmentName(environment?.name || ''),
        appletInstances: appletInstances,
        id,
      })
    )
  ) as ProjectEnvironmentsType;

  return (
    <>
      {hasSidenav && (
        <PageSidenav>
          <Button
            size="small"
            variant="ghost"
            onClick={() => navigate(routes.projects)}
          >
            <Icon name="chevron-left" slot="prefix" />
            <TranslationComponent i18nKey="manager/projects:sidebar.backToProjects">
              All Projects
            </TranslationComponent>
          </Button>
          <Spacer size="large" />
          <TextSubtitle size="small">{projectName}</TextSubtitle>
          <Spacer size="medium" />
          <Menu>
            {tabs.map(({ value, label, accordion, translationKey }) =>
              accordion ? (
                <AccordionGroup key={value}>
                  <AccordionItem open gap="xsmall">
                    <TextBody slot="header">
                      <TranslationComponent i18nKey={translationKey}>
                        {label}
                      </TranslationComponent>
                    </TextBody>
                    <Menu>
                      {!project
                        ? t('manager/common:loading', {
                            defaultValue: 'Loading...',
                          })
                        : sortedProjectEnvironments?.map((env, index) => {
                            return (
                              <MenuItem
                                key={env?.id}
                                value={env?.id}
                                selected={env?.id === environmentId}
                                onClick={() => {
                                  navigate(
                                    `${routes.projects}/${project?.projectId}/environments/${env?.id}`,
                                    { replace: true }
                                  );
                                }}
                              >
                                <Text
                                  style={{
                                    paddingInlineStart: '16px',
                                  }}
                                  key={index}
                                  size="2xsmall"
                                  fontWeight={
                                    env?.id === environmentId
                                      ? 'medium'
                                      : 'normal'
                                  }
                                >
                                  <TranslationComponent
                                    i18nKey={
                                      `manager/projects:environments.${getEnvironmentName(env?.name || '')}` as TranslationKeys
                                    }
                                  >
                                    {env?.name}
                                  </TranslationComponent>
                                </Text>
                              </MenuItem>
                            );
                          })}
                    </Menu>
                  </AccordionItem>
                </AccordionGroup>
              ) : (
                <MenuItem
                  key={value}
                  value={value}
                  selected={value === tab}
                  onClick={() => {
                    navigate(
                      `${routes.projects}/${project?.projectId}/${value}`
                    );
                  }}
                >
                  <TranslationComponent i18nKey={translationKey}>
                    {label}
                  </TranslationComponent>
                </MenuItem>
              )
            )}
          </Menu>
        </PageSidenav>
      )}
      <PageBody background="none" className="c-project-details">
        <RenderProjectDetailsContent
          project={project}
          tab={tab}
          environmentId={environmentId}
          hideDeleteButton={hideDeleteButton}
        />
      </PageBody>
    </>
  );
};
