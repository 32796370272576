import { StepperWC } from '@pypestream/design-system';
import { RefObject, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormAppletInfoType,
  FormAppletStateType,
  FormAppletToolingType,
  OutputAppletFormType,
} from '../../../../components';
import {
  sendManagerEvent,
  useManagerStateMatches,
} from '../../../../xstate/app.xstate';

const formProjectInfoDefaultValues = {
  name: '',
  description: '',
  appletVersionId: '',
  projectEnvironmentConfigId: '',
};

const formProjectToolingDefaultValues = {
  appletVersionId: '',
};

const stateDefaultValues: FormAppletStateType = {
  ...formProjectInfoDefaultValues,
  ...formProjectToolingDefaultValues,
};

export const useAppletWizardForm = (
  swiperRef: RefObject<StepperWC>,
  environmentId: string
) => {
  const [projectFormState, setProjectFormState] =
    useState<OutputAppletFormType>({
      ...stateDefaultValues,
      projectEnvironmentConfigId: environmentId,
      description: stateDefaultValues.description || '',
      name: stateDefaultValues.name || '',
    });

  const isAppletAdded = useManagerStateMatches(
    'orgRelated.ready.applets.transformAfterDelay'
  );

  const appletInstanceInfoForm = useForm<FormAppletInfoType>({
    defaultValues: {
      ...stateDefaultValues,
      projectEnvironmentConfigId: environmentId,
    },
  });

  const appletInstanceToolingForm = useForm<FormAppletToolingType>({
    defaultValues: formProjectToolingDefaultValues,
  });

  const updateFormState = (formData?: Partial<OutputAppletFormType>) => {
    if (!formData) {
      return;
    }

    setProjectFormState((prevState) => ({
      ...prevState,
      ...formData,
    }));
  };

  const createAppletInstance = (formData?: Partial<FormAppletStateType>) => {
    const projectState = {
      ...projectFormState,
      ...formData,
    };
    sendManagerEvent({
      type: 'manager.addApplet',
      ...projectState,
      name: projectState.name || '',
      description: projectState.description || '',
    });
  };

  const resetFormState = () => {
    appletInstanceInfoForm.reset();
    appletInstanceToolingForm.reset();
    setProjectFormState({
      ...stateDefaultValues,
      description: stateDefaultValues.description || '',
      name: stateDefaultValues.name || '',
    });
    swiperRef?.current?.goToStep(0);
  };

  const handleNextStep = (formData?: Partial<OutputAppletFormType>) => {
    updateFormState(formData);
    swiperRef?.current?.nextStep();
  };

  const handlePrevStep = () => swiperRef?.current?.backStep();

  useEffect(() => {
    if (isAppletAdded) {
      swiperRef?.current?.nextStep();
    }
  }, [isAppletAdded, swiperRef]);

  return {
    updateFormState,
    resetFormState,
    createAppletInstance,
    appletInstanceInfoForm,
    appletInstanceToolingForm,
    handleNextStep,
    handlePrevStep,
  };
};
