import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Environments, Loader } from '../components';
import { WithRouteData } from '../render-routes';
import { useManagerCtxSelector } from '../xstate/app.xstate';

export const DefaultEnvironmentPage = () => {
  const navigate = useNavigate();
  const { routes, project } = useManagerCtxSelector((ctx) => {
    return {
      project: ctx.projects?.find(
        ({ projectId }) => projectId === ctx.selectedProject
      ),
      routes: ctx.routes,
    };
  });

  useEffect(() => {
    if (project && project?.projectId) {
      const defaultEnv = project?.projectEnvironmentConfig?.find(
        (channel) => channel?.environment?.name === Environments.Development
      );
      navigate(
        `${routes.projects}/${project?.projectId}/environments/${defaultEnv?.id}`,
        { replace: true }
      );
    }
  }, [project, routes.projects, navigate]);

  return <Loader />;
};

export default WithRouteData(DefaultEnvironmentPage);
