import {
  BlockList,
  Grid,
  GridCell,
  PageBody,
  PageSection,
  Spacer,
  TextSubtitle,
  TextTitle,
  Tooltip,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
} from '../../../xstate/app.xstate';
import { IPage } from '../../types';
import PermissionsTable from './permissions-table';

export const UserDetails: FC<IPage> = (props) => {
  const params = useParams();
  const { org_id: orgIdQueryParam = '', user_id: userIdQueryParam = '' } =
    params;

  const { allUserDetails } = useManagerCtxSelector((ctx) => ({
    allUserDetails: ctx.userDetails,
  }));
  const userDetails = allUserDetails && allUserDetails[userIdQueryParam];
  const loadingUserDetails = useManagerStateMatches(
    'orgRelated.ready.userDetails.loading'
  );

  useEffect(() => {
    sendManagerEvent({
      type: 'manager.loadUserDetails',
      orgId: orgIdQueryParam,
      userId: userIdQueryParam,
    });
  }, [userIdQueryParam, orgIdQueryParam]);

  if (!userDetails) {
    if (loadingUserDetails) {
      return (
        <PageBody background="none">
          <PageSection>
            <Loader relative />
          </PageSection>
        </PageBody>
      );
    }
    // @todo - Add better error handling. (we may need something like LocalErrorBoundaryProps what we use with Studio app)
    return (
      <PageBody>
        <TextTitle size="small" i18nKey="manager/common:genericError">
          Something went wrong: Try again
        </TextTitle>
      </PageBody>
    );
  }

  return (
    <PageBody background="none" width="large">
      <TextTitle test-id="user-details-subtitle">
        {userDetails.firstName} {userDetails.lastName}
      </TextTitle>
      <Spacer size="2xlarge" />
      <>
        <PageSection>
          <TextTitle test-id="user-details-subtitle" size="xsmall">
            Details
          </TextTitle>
          <Spacer size="large" />

          <BlockList>
            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-first-name-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.name">
                    Name
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Grid alignItems="center">
                  <GridCell xsmall="12" medium="6">
                    {userDetails.firstName}
                  </GridCell>
                  <GridCell xsmall="12" medium="6">
                    {userDetails.lastName}
                  </GridCell>
                </Grid>
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  test-id="my-account-email-label"
                  size="small"
                  variant="secondary"
                >
                  <TranslationComponent i18nKey="manager/preferences:general.email_address">
                    Email address
                  </TranslationComponent>
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Tooltip content="To edit your email address, contact an Admin">
                  {userDetails.userName}
                </Tooltip>
              </GridCell>
            </Grid>
          </BlockList>
        </PageSection>
        <PageSection>
          <TextTitle
            test-id="permissions-table"
            size="xsmall"
            i18nKey="manager/users:userDetails.permissionAndAccessTable.title"
          >
            Permissions & Access
          </TextTitle>
          <Spacer size="large" />
          <PermissionsTable userDetails={userDetails} />
        </PageSection>
      </>
    </PageBody>
  );
};
