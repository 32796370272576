import { ProductName } from '@pypestream/api-services';
import {
  Button,
  Dropdown,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuItem,
  Spacer,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tag,
  TextBody,
  TextCaption,
  TextOverline,
  logos,
} from '@pypestream/design-system';
import { compact } from 'lodash';
import React, { FC, memo } from 'react';
import { useManagerCtxSelector } from '../../../xstate/app.xstate';
import {
  ShortOrgType,
  UserAccountProject,
  UserDetailsType,
  UserTeams,
} from '../../../xstate/manager.xstate';

type PermissionsTableProps = {
  userDetails: UserDetailsType;
};

const getProductIcon = (productName: string) => {
  switch (productName) {
    case ProductName.AgentAssist:
      return logos.agentAssist;
    case ProductName.Analytics:
      return logos.analytics;
    case ProductName.Organization:
      return logos.org;
    default:
      return 'Unknown Product';
  }
};

const OrgCell: FC<{ org: UserDetailsType['orgs'][number] }> = ({ org }) => (
  <TableCell rowspan={org.orgRowSpan}>
    <Stack alignItems="start" gutter="2xsmall" direction="column">
      <Stack alignItems="center" gutter="2xsmall">
        {org.isPrimaryAccount && (
          <Icon name="home" size="xsmall" weight="medium" color="gray"></Icon>
        )}
        <TextBody variant="secondary">{org.name}</TextBody>
      </Stack>
      {org.parentAccount && (
        <TextBody
          variant="tertiary"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.orgParent"
        >
          Parent: {org.parentAccount?.name}
        </TextBody>
      )}
    </Stack>
    <Spacer size="large"></Spacer>
    <Stack alignItems="center" gutter="xsmall">
      <Icon name="teams" size="xsmall" weight="medium" color="gray"></Icon>
      <TextOverline>Teams</TextOverline>
    </Stack>
    <Spacer size="medium"></Spacer>
    {(org.assignedTeams && org.assignedTeams?.length > 0 && (
      <>
        <Stack direction="column">
          {org.assignedTeams?.map((team) => (
            <Tag
              minimal
              removable
              new-variant="outlined"
              key={`${org.id}-${team.team.id}`}
            >
              <Icon name="teams"></Icon>
              <TeamName team={team} />
            </Tag>
          ))}
        </Stack>
        <Spacer size="medium"></Spacer>
      </>
    )) ||
      null}
    {(org.availableTeams && org.availableTeams?.length > 0 && (
      <>
        <Button
          variant="ghost"
          size="small"
          id={`org-${org.id}-team-seletor`}
          i18nKey="manager/teams:addTeam"
        >
          <Icon slot="prefix" name="add"></Icon>
          Add Team
        </Button>
        <Dropdown
          trigger={`org-${org.id}-team-seletor`}
          placement="bottom-start"
          closeOnClickOutside
        >
          <Menu>
            {org.availableTeams?.map((team) => (
              <MenuItem key={team.id}>{team.name}</MenuItem>
            ))}
          </Menu>
        </Dropdown>
      </>
    )) ||
      (org.assignedTeams?.length == 0 && (
        <TextBody
          variant="secondary"
          size="small"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.teamEmptyState"
        >
          No teams found.
        </TextBody>
      )) ||
      null}
    <Spacer size="large"></Spacer>
    <Stack alignItems="center" gutter="xsmall">
      <Image width="16" height="16" src={logos.manager}></Image>
      <TextOverline>Manager</TextOverline>
    </Stack>
    <Spacer></Spacer>
    <Stack direction="column">
      {(org.accountRoles &&
        org.accountRoles?.length > 0 &&
        org.accountRoles?.map((role, index) => {
          const isTeamInheritedRole = role.teams && role?.teams.length > 0;
          return (
            <Tag
              minimal
              new-variant="outlined"
              key={`${org.id}-${role.role.id}-${isTeamInheritedRole ? 'inherited-role' : 'direct-role'}`}
              removable={!role?.teams}
            >
              <Icon name={isTeamInheritedRole ? 'teams' : 'users'}></Icon>
              {role.role.name}
              {isTeamInheritedRole && (
                <RenderViaTeamDetails teams={role.teams || []} />
              )}
            </Tag>
          );
        })) || (
        <Tag
          minimal
          new-variant="outlined"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.roles.viewOnly"
        >
          <Icon name="users"></Icon>
          View Only
        </Tag>
      )}
    </Stack>
  </TableCell>
);

const ProjectNameCell: FC<{
  project: UserAccountProject;
}> = ({ project }) => (
  <TableCell
    rowspan={project.project?.project_product_settings?.length || 1}
    style={{ minWidth: 120 }}
  >
    <TextBody variant="secondary" size="small">
      {project?.project.name}
    </TextBody>
  </TableCell>
);

const ProjectProductCell: FC<{
  projectProductSetting: UserAccountProject['project']['project_product_settings'][number];
}> = ({ projectProductSetting }) => (
  <TableCell>
    <Stack gutter="xsmall" nowrap>
      <Image
        width="16"
        height="16"
        src={getProductIcon(projectProductSetting.product.name)}
      />
      <TextCaption style={{ marginRight: 12 }}>
        {projectProductSetting.product?.displayName}
      </TextCaption>
    </Stack>
  </TableCell>
);

const ProjectProductRoleCell: FC<{
  project: UserAccountProject;
  productId: string;
}> = ({ project, productId }) => {
  const availableProductRoles = project.availableProjectRoles?.filter(
    (role) => role.productId === productId
  );
  const roleSelectorId = `role-selector-${project.id}-${productId}`;
  const rolePills = compact(
    project._user_account_project_roles.map(
      (_user_account_project_role, index) => {
        const isTeamInheritedRole =
          _user_account_project_role.teams &&
          _user_account_project_role.teams.length > 0;

        const canSelectMoreRole =
          !isTeamInheritedRole && availableProductRoles?.length;

        return (
          (productId === _user_account_project_role.role.productId && (
            <Tag
              minimal
              key={`${project.id}-${_user_account_project_role.role.id}-${index}`}
              removable={!isTeamInheritedRole}
              id={roleSelectorId}
              interactive={!!canSelectMoreRole}
              new-variant="outlined"
            >
              <Icon name={isTeamInheritedRole ? 'teams' : 'users'}></Icon>
              {_user_account_project_role.role.name}
              {isTeamInheritedRole && (
                <RenderViaTeamDetails
                  teams={
                    (_user_account_project_role.teams &&
                      _user_account_project_role.teams) ||
                    []
                  }
                />
              )}
              {(canSelectMoreRole && (
                <>
                  <Icon name="chevron-down"></Icon>
                  <Dropdown trigger={roleSelectorId} placement="bottom-start">
                    <Menu>
                      {availableProductRoles?.map((availableRole) => (
                        <MenuItem key={availableRole.id}>
                          {availableRole.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Dropdown>
                </>
              )) ||
                null}
            </Tag>
          )) ||
          null
        );
      }
    )
  );

  return (
    <TableCell>
      <Stack alignItems="center" gutter="xsmall">
        {rolePills}
        {/* Ability to add direct role only if no role is assigned to product*/}
        {!rolePills.length && (
          <>
            <IconButton name="add" id={roleSelectorId} />
            <Dropdown trigger={roleSelectorId} placement="bottom-start">
              <Menu>
                {availableProductRoles?.map((availableRole) => (
                  <MenuItem key={availableRole.id}>
                    {availableRole.name}
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          </>
        )}
      </Stack>
    </TableCell>
  );
};

const AddProjectCell: FC<{
  org: ShortOrgType;
}> = ({ org }) => (
  <TableCell colspan={3}>
    {(org.availableProjects && org.availableProjects?.length > 0 && (
      <>
        <Button
          variant="ghost"
          size="small"
          id={`org-${org.id}-project-seletor`}
          i18nKey="manager/users:userDetails.permissionAndAccessTable.addProject"
        >
          <Icon slot="prefix" name="add"></Icon>
          Add Project
        </Button>
        <Dropdown
          trigger={`org-${org.id}-project-seletor`}
          placement="bottom-start"
        >
          <Menu>
            {org.availableProjects?.map((project) => (
              <MenuItem key={project.id}>{project.name}</MenuItem>
            ))}
          </Menu>
        </Dropdown>
      </>
    )) ||
      (org.assignedProjects?.length == 0 && (
        <TextBody
          variant="secondary"
          size="small"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.projectsEmptyState"
        >
          No projects found. Create a project to get started.
        </TextBody>
      )) ||
      null}
  </TableCell>
);

const RenderViaTeamDetails = ({ teams }: { teams: UserTeams }) => {
  return (
    <span color="primary">
      {teams?.map((team, teamIndex) => (
        <React.Fragment key={team.team.id}>
          {/* Add opening bracket and via text for first team name */}
          {teamIndex === 0 && '(via - '}
          <TeamName team={team} />
          {/* Add closing bracket for last team name OR else add comma separator */}
          {(teamIndex + 1 == teams.length && `)`) || `, `}
        </React.Fragment>
      ))}
    </span>
  );
};

const TeamName = ({ team }: { team: UserTeams[number] }) => {
  // @todo - Can we improve how we are getting `adminBasePath`?
  const { adminBasePath } = useManagerCtxSelector((ctx) => ({
    adminBasePath: ctx.routes.admin,
  }));
  return (
    <a
      key={team.team.id}
      href={`${adminBasePath}/organization/${team.team.team_accounts[0]?.accountId}/teams-management/${team.team.id}`}
    >
      {team.team.name}
    </a>
  );
};

export const PermissionsTable: FC<PermissionsTableProps> = ({
  userDetails,
}) => (
  <Table size="medium" bordered>
    <TableHead>
      <TableRow>
        <TableCell
          variant="th"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.orgTitle"
        >
          Org
        </TableCell>
        <TableCell
          variant="th"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.projectTitle"
        >
          Project
        </TableCell>
        <TableCell
          variant="th"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.toolTitle"
        >
          Tool
        </TableCell>
        <TableCell
          variant="th"
          i18nKey="manager/users:userDetails.permissionAndAccessTable.roleTitle"
        >
          Role
        </TableCell>
      </TableRow>
    </TableHead>

    <TableBody>
      {userDetails.orgs.map((org: ShortOrgType) => (
        <>
          <TableRow key={org.id}>
            <OrgCell org={org} />
            {(org.assignedProjects && org.assignedProjects.length > 0 && (
              <>
                <ProjectNameCell project={org.assignedProjects[0]} />
                <ProjectProductCell
                  projectProductSetting={
                    org.assignedProjects[0].project.project_product_settings[0]
                  }
                />
                <ProjectProductRoleCell
                  project={org.assignedProjects[0]}
                  productId={
                    org.assignedProjects[0].project.project_product_settings[0]
                      .product.id
                  }
                />
              </>
            )) ||
              (org.showAddProjectButton && <AddProjectCell org={org} />) || (
                // Else render empty cells to keep table alignment as expected
                <>
                  <TableCell key="empty-cell-1"></TableCell>
                  <TableCell key="empty-cell-2"></TableCell>
                  <TableCell key="empty-cell-3"></TableCell>
                </>
              )}
          </TableRow>
          {org.assignedProjects?.map((project, projectIndex) => {
            if (projectIndex === 0) {
              return project.project?.project_product_settings
                ?.slice(1)
                ?.map((projectProductSetting) => {
                  return (
                    <TableRow
                      key={`${project.id}-${projectProductSetting.product?.id}`}
                    >
                      <ProjectProductCell
                        projectProductSetting={projectProductSetting}
                      />
                      <ProjectProductRoleCell
                        project={project}
                        productId={projectProductSetting.product.id}
                      />
                    </TableRow>
                  );
                });
            }
            return (
              <>
                <TableRow key={project.id}>
                  <ProjectNameCell project={project} />
                  {project.project?.project_product_settings?.length > 0 ? (
                    <>
                      <ProjectProductCell
                        projectProductSetting={
                          project.project?.project_product_settings[0]
                        }
                      />
                      <ProjectProductRoleCell
                        project={project}
                        productId={
                          project.project?.project_product_settings[0].product
                            .id
                        }
                      />
                    </>
                  ) : (
                    // Else render empty cells to keep table alignment as expected
                    <>
                      <TableCell key="empty-cell-4"></TableCell>
                      <TableCell key="empty-cell-5"></TableCell>
                    </>
                  )}
                </TableRow>
                {project.project?.project_product_settings?.map(
                  (projectProductSetting, projectProductSettingInd) => {
                    if (projectProductSettingInd === 0) {
                      return null;
                    }
                    return (
                      <TableRow
                        key={`${project.id}-${projectProductSetting.product?.id}`}
                      >
                        <ProjectProductCell
                          projectProductSetting={projectProductSetting}
                        />
                        <ProjectProductRoleCell
                          project={project}
                          productId={projectProductSetting.product.id}
                        />
                      </TableRow>
                    );
                  }
                )}
              </>
            );
          })}
          {((org.assignedProjects || []).length > 0 &&
            org.showAddProjectButton &&
            (org.availableProjects || []).length > 0 && (
              <TableRow>
                <AddProjectCell org={org} />
              </TableRow>
            )) ||
            null}
        </>
      ))}
    </TableBody>
  </Table>
);

export default memo(PermissionsTable);
