import {
  GetAppletTemplatesQuery,
  GetPackagesQuery,
  ProjectEnvironmentConfigByIdQuery,
  urqlGql,
} from '@pypestream/api-services/urql';
import Builder from '@pypestream/app-loader-builder';
import { Button, JSONSchemaForm, PageBody } from '@pypestream/design-system';
import validator from '@rjsf/validator-ajv8';
import { CSSProperties, useCallback, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import { IPage } from '../pages/types';
import { WithRouteData } from '../render-routes';

type PlaygroundStateType = {
  appletTemplates: NonNullable<GetAppletTemplatesQuery['admin_']>['applets'];
  packages: NonNullable<GetPackagesQuery['admin_']>['packages'];
  appletInstances: NonNullable<
    NonNullable<
      NonNullable<ProjectEnvironmentConfigByIdQuery['admin_']>
    >['projectEnvironmentConfigById']
  >['appletInstances'];
  appletInstanceIdForEdit: string;
  loadingAppletTemplate: boolean;
  loadingAppletInstances: boolean;
  loadingAppletPackages: boolean;
};

const PlaygroundInitialState: PlaygroundStateType = {
  appletTemplates: [],
  packages: [],
  appletInstances: [],
  appletInstanceIdForEdit: '',
  loadingAppletTemplate: false,
  loadingAppletInstances: false,
  loadingAppletPackages: false,
};

// An interface for our actions
type PlaygroundActionTypes =
  | {
      type: 'SET_LOADING_APPLET_TEMPLATE';
      payload: boolean;
    }
  | {
      type: 'SET_LOADING_APPLET_INSTANCE';
      payload: boolean;
    }
  | {
      type: 'SET_LOADING_APPLET_PACKAGES';
      payload: boolean;
    }
  | {
      type: 'SET_PACKAGES';
      payload: PlaygroundStateType['packages'];
    }
  | {
      type: 'SET_APPLET_TEMPLATES';
      payload: PlaygroundStateType['appletTemplates'];
    }
  | {
      type: 'SET_APPLET_INSTANCES';
      payload: PlaygroundStateType['appletInstances'];
    }
  | {
      type: 'SELECT_APPLET_INSTANCES_ID';
      payload: string;
    };

const playgroundReducer = (
  playgroundState: PlaygroundStateType,
  action: PlaygroundActionTypes
): PlaygroundStateType => {
  const { type, payload } = action;
  if (type === 'SET_LOADING_APPLET_INSTANCE') {
    return {
      ...playgroundState,
      loadingAppletInstances: payload,
    };
  } else if (type === 'SET_LOADING_APPLET_PACKAGES') {
    return {
      ...playgroundState,
      loadingAppletPackages: payload,
    };
  } else if (type === 'SET_LOADING_APPLET_TEMPLATE') {
    return {
      ...playgroundState,
      loadingAppletTemplate: payload,
    };
  } else if (type === 'SET_APPLET_TEMPLATES') {
    return {
      ...playgroundState,
      appletTemplates: payload,
    };
  } else if (type === 'SET_APPLET_INSTANCES') {
    return {
      ...playgroundState,
      appletInstances: payload,
    };
  } else if (type === 'SET_PACKAGES') {
    return {
      ...playgroundState,
      packages: payload,
    };
  } else if (type === 'SELECT_APPLET_INSTANCES_ID') {
    console.log('>>>>payload', payload);
    return {
      ...playgroundState,
      appletInstanceIdForEdit: payload,
    };
  }
  return playgroundState;
};

export const EnvironmentPage = (props: IPage) => {
  const params = useParams();
  const { environment_id: environmentIdQueryParam } = params;

  const [playgroundState, dispatch] = useReducer(
    playgroundReducer,
    PlaygroundInitialState
  );

  const getAppletTemplates = useCallback(async () => {
    dispatch({ type: 'SET_LOADING_APPLET_TEMPLATE', payload: true });
    const applets = await urqlGql.getAppletTemplates();
    if (applets.data?.admin_?.applets) {
      dispatch({
        type: 'SET_APPLET_TEMPLATES',
        payload: applets.data?.admin_?.applets,
      });
    }
    dispatch({ type: 'SET_LOADING_APPLET_TEMPLATE', payload: false });
  }, []);

  const getAllAppletInstances = useCallback(async () => {
    if (environmentIdQueryParam) {
      dispatch({ type: 'SET_LOADING_APPLET_INSTANCE', payload: true });
      const environmentConfig_ = await urqlGql.projectEnvironmentConfigById({
        projectEnvironmentConfigByIdId: environmentIdQueryParam,
      });
      if (
        environmentConfig_.data?.admin_?.projectEnvironmentConfigById
          ?.appletInstances
      ) {
        dispatch({
          type: 'SET_APPLET_INSTANCES',
          payload:
            environmentConfig_.data?.admin_?.projectEnvironmentConfigById
              ?.appletInstances,
        });
      }
      dispatch({ type: 'SET_LOADING_APPLET_INSTANCE', payload: false });
    }
  }, [environmentIdQueryParam]);

  const getPackages = useCallback(async () => {
    dispatch({ type: 'SET_LOADING_APPLET_PACKAGES', payload: true });
    const applets = await urqlGql.getPackages();
    if (applets.data?.admin_?.packages) {
      dispatch({
        type: 'SET_PACKAGES',
        payload: applets.data?.admin_?.packages,
      });
    }
    dispatch({ type: 'SET_LOADING_APPLET_PACKAGES', payload: false });
  }, []);

  useEffect(() => {
    getAppletTemplates();
    getAllAppletInstances();
    getPackages();
  }, [getAllAppletInstances, getAppletTemplates, getPackages]);

  const loadingStyle = (loading: boolean): CSSProperties => ({
    pointerEvents: loading ? 'none' : 'auto',
    opacity: loading ? 0.5 : 1,
  });

  const appletToEdit = playgroundState.appletInstances?.find(
    (a) => a.id === playgroundState.appletInstanceIdForEdit
  );
  return (
    <PageBody background="none">
      <a
        href="https://docs.google.com/document/d/1bdKDLwPDfBVdCYOxVDmYiZFIZGaBbMYcHnklUayo1K0/edit?usp=sharing"
        target="_blank"
        rel="noreferrer"
      >
        Quick guide - How to use playground
      </a>
      <hr />
      <div>
        <div style={loadingStyle(playgroundState.loadingAppletTemplate)}>
          <Button onClick={getAppletTemplates}>
            Get Available Applet Templates
          </Button>
          <div>
            Available Applet Templates -
            {playgroundState.appletTemplates?.map((applet, index) => (
              <div key={`appletIndex:${index}-appledId:${applet.id}`}>
                <div>
                  <div>
                    {index + 1}: name - {applet.displayName || applet.pkgName}
                  </div>
                  <div>appletType - {applet.appletType}</div>
                  <div>appletCategory - {applet.appletCategory}</div>
                  <div>applet id - {applet.id}</div>
                  <div>latest version id - {applet.latestVersion?.id}</div>
                  <div>schema - {applet.latestVersion?.schema}</div>
                </div>
                <br />
              </div>
            ))}
          </div>
        </div>
        <hr />
        <div style={loadingStyle(playgroundState.loadingAppletPackages)}>
          <Button onClick={getPackages}>Get Available Packages</Button>
          <div>
            Available Packages -
            {playgroundState.packages?.map((pkg, index) => (
              <div key={`packages-${pkg.id}`}>
                <div>
                  {index + 1}: name - {pkg.displayName}
                </div>
                <div>Package name - {pkg.pkgName}</div>
                <div>Package type - {pkg.packageTypes}</div>
                <div>Package id - {pkg.id}</div>
                <div>latest version - {pkg.latestVersion?.version}</div>
                <div>latest version id - {pkg.latestVersion?.id}</div>
                <div>schema - {`${pkg.latestVersion?.schema}`}</div>
                <br />
              </div>
            ))}
          </div>
          <hr />
          Add Package OR New Version To Existing Package-
          <JSONSchemaForm
            schema={{
              title: 'Create Package',
              type: 'object',
              required: [
                'displayName',
                'pkgName',
                'version',
                'schema',
                'packageTypes',
                'description',
              ],
              properties: {
                displayName: {
                  type: 'string',
                  title: 'Package display name',
                },
                pkgName: {
                  type: 'string',
                  title: 'Package name',
                },
                version: {
                  type: 'string',
                  title: 'Package version',
                },
                description: {
                  type: 'string',
                  title: 'Package description',
                },
                packageTypes: {
                  type: 'string',
                  title: 'Package Type',
                  enum: [
                    'TRIGGER',
                    'CONDITION',
                    'ACTION',
                    'FUNCTION',
                    'UNDEFINED',
                    '',
                  ],
                },
                schema: {
                  type: 'string',
                  title: 'Package schema',
                },
              },
            }}
            uiSchema={{
              schema: {
                'ui:widget': 'textarea',
                'ui:options': {
                  rows: 3,
                },
                'ui:help': 'JSON Schema for package.',
              },
              packageTypes: {
                'ui:help': 'Select package type',
              },
            }}
            validator={validator}
            onSubmit={async (data) => {
              dispatch({ type: 'SET_LOADING_APPLET_PACKAGES', payload: true });
              await urqlGql.createPackage({
                pkgName: data.formData.pkgName,
                version: data.formData.version,
                displayName: data.formData.displayName,
                schema: JSON.stringify(JSON.parse(data.formData.schema)),
                packageTypes: data.formData.packageTypes,
                packageJson: {},
                description: data.formData.description,
              });
              getPackages();
              dispatch({ type: 'SET_LOADING_APPLET_PACKAGES', payload: false });
            }}
          />
        </div>
        <hr />
        <div style={loadingStyle(playgroundState.loadingAppletInstances)}>
          Applet instances operations
          <Button onClick={getAllAppletInstances}>
            Get all applet instances
          </Button>
          <div>
            {playgroundState.appletInstances?.map((appletInstance) => (
              <div key={appletInstance.id}>
                <div>appletName - {appletInstance.name}</div>
                <div>
                  appletType - {appletInstance.applet.applet.appletType}
                </div>
                <div>applet instance status - {appletInstance.status}</div>
                <div>appletInstance id - {appletInstance.id}</div>
                <div>
                  conditions - {JSON.stringify(appletInstance.condition)}
                </div>
                <div>triggers - {JSON.stringify(appletInstance.triggers)}</div>
                <div>actions - {JSON.stringify(appletInstance.actions)}</div>
                <br />
              </div>
            ))}
          </div>
          <hr />
          <div>
            <div>Add Applet Instance to release channel -</div>
            <JSONSchemaForm
              schema={{
                type: 'object',
                required: ['appletVersionId', 'name', 'status'],
                properties: {
                  appletVersionId: {
                    type: 'string',
                    title: 'Applet version id',
                    oneOf: playgroundState.appletTemplates?.map((template) => ({
                      const: template.latestVersion?.id,
                      title: template.displayName,
                    })),
                  },
                  name: {
                    type: 'string',
                    title: 'Applet name',
                  },
                  status: {
                    type: 'string',
                    title: 'Enable applet?',
                    enum: ['INACTIVE', 'ACTIVE'],
                  },
                },
              }}
              uiSchema={{
                appletVersionId: {
                  'ui:help': 'Select type of applet',
                },
                status: {
                  'ui:help': 'Select status of appletInstance',
                },
              }}
              validator={validator}
              onSubmit={async (data) => {
                if (environmentIdQueryParam) {
                  dispatch({
                    type: 'SET_LOADING_APPLET_INSTANCE',
                    payload: true,
                  });
                  await urqlGql.createAppletInstance({
                    appletVersionId: data.formData.appletVersionId,
                    status: data.formData.status,
                    projectEnvironmentConfigId: environmentIdQueryParam,
                    name: data.formData.name,
                  });
                  getAllAppletInstances();
                  dispatch({
                    type: 'SET_LOADING_APPLET_INSTANCE',
                    payload: false,
                  });
                }
              }}
            />
            <hr />
            <div>
              <div>Update applet Instance</div>
              <JSONSchemaForm
                schema={{
                  type: 'object',
                  required: ['appletInstanceId'],
                  properties: {
                    appletInstanceId: {
                      type: 'string',
                      title: 'Applet version id',
                      oneOf: playgroundState.appletInstances?.map((applet) => ({
                        const: applet.id,
                        title: applet.name,
                      })),
                    },
                  },
                }}
                uiSchema={{
                  appletInstanceId: {
                    'ui:help': 'Select applet instance to edit',
                  },
                }}
                validator={validator}
                onSubmit={async (data) => {
                  dispatch({
                    type: 'SELECT_APPLET_INSTANCES_ID',
                    payload: data.formData.appletInstanceId,
                  });
                }}
              />
              {playgroundState.appletInstanceIdForEdit && appletToEdit && (
                <Builder
                  applet={appletToEdit}
                  onSubmit={(data) =>
                    urqlGql
                      .updateAppletInstance(data)
                      .toPromise()
                      .then((res) => alert(JSON.stringify(res.data, null, 4)))
                      .catch((err) =>
                        alert(JSON.stringify(err.message, null, 4))
                      )
                  }
                  key={playgroundState.appletInstanceIdForEdit}
                />
              )}
            </div>
          </div>
          <hr />
          <div>
            Delete Applet instance -
            <JSONSchemaForm
              schema={{
                type: 'object',
                required: ['appletInstanceId'],
                properties: {
                  appletInstanceId: {
                    type: 'string',
                    title: 'Applet version id',
                    oneOf: playgroundState.appletInstances?.map((applet) => ({
                      const: applet.id,
                      title: applet.name,
                    })),
                  },
                },
              }}
              uiSchema={{
                appletInstanceId: {
                  'ui:help': 'Select applet instance to delete',
                },
              }}
              validator={validator}
              onSubmit={async (data) => {
                dispatch({
                  type: 'SET_LOADING_APPLET_INSTANCE',
                  payload: true,
                });
                await urqlGql.deleteAppletInstance({
                  deleteAppletInstanceId: data.formData.appletInstanceId,
                });
                getAllAppletInstances();
                dispatch({
                  type: 'SET_LOADING_APPLET_INSTANCE',
                  payload: false,
                });
              }}
            />
          </div>
        </div>
      </div>
    </PageBody>
  );
};

export default WithRouteData(EnvironmentPage);
