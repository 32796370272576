/* eslint-disable lit/no-invalid-html, lit/binding-positions */
import { unsafeCSS } from 'lit';
import { html, literal } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';

import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../base-element';
import tableCellStyles from './table-cell.scss?inline';
import { Translate } from '../base-element/mixins/translation-mixin';

@customElement('ps-table-cell')
export class TableCellWC extends Translate(BaseElement) {
  static styles = unsafeCSS(tableCellStyles);

  /** Table cell variant */
  @property({ reflect: true }) variant: 'th' | 'td' = 'td';

  /** Indicate cell having active sort */
  @property({ reflect: true, type: Boolean }) sortActive = false;

  /** Indicate cell having sorting button */
  @property({ reflect: true, type: Boolean }) sortable = false;

  /** Set alignment of cell */
  @property({ reflect: true }) align: 'start' | 'center' | 'end' = 'start';

  /**  Don't break items into multiple lines */
  @property({ reflect: true, type: Boolean }) nowrap = false;

  /** Specifies the number of rows a cell should span */
  @property({ reflect: true, type: Number }) rowspan: number;

  /** Specifies the number of columns a cell should span */
  @property({ reflect: true, type: Number }) colspan: number;

  render() {
    const sortingButtonClasses = `c-table-cell__sorting-button ${
      this.align === 'end' ? 'c-table-cell--align-end' : ''
    }`;

    const tag = this.variant === 'td' ? literal`td` : literal`th`;

    const role = this.variant === 'th' ? 'columnheader' : 'gridcell';

    return html`
      <${tag} role="${role}" rowspan="${this.rowspan}" colspan="${this.colspan}">
        <div
          class="${classMap({
            'c-table-cell': true,
            [`c-table-cell--nowrap`]: this.nowrap,
            [`c-table-cell--align-${this.align}`]: this.align,
            [`c-table-cell--sort-active`]: this.sortActive,
          })}"
        >
          <div class="c-table-cell__content">
            ${
              this.sortable
                ? html`<button class="${sortingButtonClasses}">
                    <slot></slot>
                    <slot name="sorter" class="c-table-cell__sorter"></slot>
                  </button>`
                : html`<slot></slot>`
            }
          </div>
        </div>
      </${tag}>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-table-cell': TableCellWC;
  }
  enum PSElementTagNameMap {
    'ps-table-cell' = 'ps-table-cell',
  }
}
